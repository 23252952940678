import React, { useEffect, useState, useRef } from "react";
import { 
  BrowserRouter as Router,
  Route, 
  Routes, 
  withRouter, 
  useRoutes 
} from "react-router-dom";

import Home from "./views/home";

function App() {  

  useEffect(() => {
    
  }, []);  

  const AppRoute = () => {
    let routes = useRoutes([
      {
        path: '/',
        element: <Home />,
        children: [
          {
            path: '/',
            element: <Home />,
          },
          {
            path: ':pid',
            element: <Home />,
          }
        ]
      },
      // {
      //   path: '/:pid',
      //   element: <Home />
      // }
    ])
    return routes
  }

  return (
    <Router>
      <AppRoute />
    </Router>
  );
}

export default App;
