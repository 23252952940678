import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { 
  Container, 
  Form, 
  Row, 
  Col,
  Button,
  Group,
  Check,
  Label,
  Text
} from "react-bootstrap";
import { 
  useParams
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import '../../assets/scss/style.scss';

import Modal from '../../components/elements/Modal';

import Logo from "../../assets/images/logo.webp";

const firebaseConfig = {
  apiKey: "AIzaSyAjnR45TF1PJ16Xcve-ZExeGpBWb2dyTaQ",
  projectId: "t2g-app",
  databaseURL: "https://t2g-app.firebaseio.com",
  storageBucket: "t2g-app.appspot.com",
  appId: "1:667083673387:web:e9df660b49b66097b0ff6e",
  authDomain: "t2g-app.firebaseapp.com",
};
firebase.initializeApp(firebaseConfig);

function Home() {
  const { pid } = useParams();  

  const [showSplash, setShowSplash] = useState(true);
  const [message, setMessage] = useState('');
  const [player, setPlayer] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [gpa, setGPA] = useState(null);
  const [act, setACT] = useState(null);
  const [sat, setSAT] = useState(null);
  const [gcl, setGCL] = useState(null);  
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    getShareInfo()
  }, []);

  const getShareInfo = () => {
    // let shareId = window.location.pathname.substring(1)
    // if (!shareId) {
    console.log('///////// pid', pid);
    if (!pid) {
      setMessage('...')
      setShowSplash(true)      
      return
    }

    let query = firebase.firestore()
      .collection('CounselorLink')
      .doc(pid);
    // query.onSnapshot((snapshot) => {
    query.get().then((snapshot) => {
      if (!snapshot.exists) {
        setMessage('You can\'t edit this player\'s info anymore.\nPlease contact the head coach.')
        setShowSplash(true)
        return
      }
      let shareInfo = snapshot.data()
      console.log('shareInfo', shareInfo);
      let createdAt = shareInfo.createdAt;
      let expiredAt = createdAt.seconds + 24 * 3600;
      let serverNow = firebase.firestore.Timestamp.now();
      // Check Expiration
      if (expiredAt < serverNow.seconds) {
        setMessage('You can\'t edit this player\'s info anymore.\nPlease contact the head coach.');
        setShowSplash(true);
        return;
      }
      
      let idValue = shareInfo.player; // Player ID
      setPlayerId(idValue);

      getPlayerInfo(idValue);
    });
  }  

  const getPlayerInfo = async (playerId) => {
    console.log('playerId', playerId)

    let query = firebase.firestore()
      .collection('Player')
      .doc(playerId);
    query.onSnapshot((snapshot) => {
      if (!snapshot.exists) {
        setMessage('No player data found!');
        setShowSplash(true);
        return
      }

      let playerInfo = snapshot.data();
      console.log('playerInfo', playerInfo);
      setPlayer(playerInfo);
      setGPA(playerInfo.gpa);
      setACT(playerInfo.act);
      setSAT(playerInfo.sat);
      setGCL(playerInfo.graduatingClass);

      setShowSplash(false);
    });
  } 

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const openModal = (e, video) => {
    console.log('openModal...', e)
    e.preventDefault();

    setVideo(video);
    setVideomodalactive(true);
  }

  const handleSubmit = (event) => {
    setValidated(true);

    event.preventDefault();
    event.stopPropagation();      

    const form = event.currentTarget;
    if (form.checkValidity() === false) {      
      return;
    }
    
    let gpaValue = parseFloat(gpa);
    let actValue = parseFloat(act);
    let satValue = parseFloat(sat);
    let gclValue = gcl.toString();
    if (isNaN(gpaValue)) {
      gpaValue = 0;
    }
    if (isNaN(actValue)) {
      actValue = 0;
    }
    if (isNaN(satValue)) {
      satValue = 0;
    }

    let query = firebase.firestore()
      .collection('Player')
      .doc(playerId);
    query.update({
      'gpa': gpaValue,
      'act': actValue,
      'sat': satValue,
      'graduatingClass': gclValue,
    })
    .then(() => {
      console.log("Player successfully updated!");
    });
  };

  return (
    <div className="App">

      <div className="App-header">
        <div className='Header-container'>
          <a
            href="https://t2gapp.com/"
            target='_blank'
          >
            <img className='Header-logo' src={Logo} />
          </a>
          <div className='Header-info m-2'>
            <FontAwesomeIcon 
              icon={faPhoneAlt} 
              color={'#FFFFFF'}
              size={'1x'}
            />
            <p className='text-nowrap mb-0'>(256) 344-4130 (office)</p>
            <FontAwesomeIcon 
              icon={faEnvelope} 
              color={'#FFFFFF'}
              size={'1x'}
            />
            <a
              href='mailto:support@ticket2greatness.com'
            >
              <p className='mb-0'>support@ticket2greatness.com</p>
            </a>
          </div>
        </div>
      </div>

      <div className='App-content mt-5'>
        {showSplash && (
          <p>{message}</p>
        )}

        <Form className='Form-container m-3 p-3' noValidate validated={validated} onSubmit={handleSubmit}>

          <Form.Group className="mb-2">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Full Name" 
              value={ player ? player.fullName: '' }
              readOnly
            />
            { /*<Form.Text className="text-black">
              { player ? player.fullName: '' }
            </Form.Text> */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="player@t2g.com" 
              value={ player ? player.email: '' }
              readOnly
            />
            { /*<Form.Text className="text-black">
              { player ? player.fullName: '' }
            </Form.Text> */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>GPA</Form.Label>
            <Form.Control 
              value={ gpa ? gpa: '' }
              onChange={ (e) => {
                setGPA(e.target.value);
              }}
              type="number" 
              placeholder="0.0"               
              required
            />
            { /*<Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */ }            
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>ACT</Form.Label>
            <Form.Control 
              value={ act ? act: '' }
              onChange={ (e) => {
                setACT(e.target.value);
              }}
              type="number" 
              placeholder="0.0" 
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>SAT</Form.Label>
            <Form.Control 
              value={ sat ? sat: '' }
              onChange={ (e) => {
                setSAT(e.target.value);
              }}
              type="number" 
              placeholder="0" 
            />
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label>Graduating Class</Form.Label>
            <Form.Control 
              value={ gcl ? gcl: '' }
              onChange={ (e) => {
                setGCL(e.target.value);
              }}
              type="number" 
              placeholder="0" 
              required
            />
          </Form.Group>
          
          { /* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group> */ }
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        
      </div>      
    </div>
  );
}

export default Home;
